import React, { Component, useState } from 'react';
import logo from './logo.svg';
import './App.css';


function App() {

  const [count, setCount] = useState(0);

  document.title = 'GG my G';

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <label>{count}</label>
        <div className="button-container">
          <button className="button" onClick={() => setCount(count + 1)}>+</button>
          <button className="button" onClick={() => setCount(count != 0 ? count - 1 : count)}>-</button>
        </div> */}
      </header>
    </div>
  );
}


export default App;
